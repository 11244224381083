import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/inter';
import '@fontsource/inter/400.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/pagination';
// import 'react-tabs/style/react-tabs.css';

import './src/styles/index.scss';

require('intersection-observer');
